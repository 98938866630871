import { History } from "history";
import { v4 as uuidv4 } from "uuid";

import { readCookie } from "../../shared/util/readCookie";
import { getPlanPriceById } from "../util/getPlanPriceById";
import { formatPhoneNumber } from "../../shared/util/formatPhoneNumber";
import { getPlanRecPlanPriceById } from "../util/getPlanRecPlanPriceById";
import {
  SignupStepOneInfoResponseData,
  SignupStepTwoInfoResponseData,
  FormHookDispState,
  PostalCodeResponseData,
  SignupStepThreeInfoResponseData,
  StringNumbers,
  MercadoPagoSDKProps,
  MercadoPagoCardInfo,
  SignupPricesAndTaxesData,
  AuthContextProps,
  AddonType,
  AddonOmnichannelType,
  NewAddonResponse,
  GetAddonStatusResponse,
} from "../../shared/data/types";

interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
}

interface GetPlansPricesProps extends FetchProps {
  setLoadedPrices: React.Dispatch<
    React.SetStateAction<SignupPricesAndTaxesData>
  >;
}

export const getPlansPrices = async (props: GetPlansPricesProps) => {
  const { sendRequest, setLoadedPrices } = props;

  try {
    const responseData: {
      pricesInfo: SignupPricesAndTaxesData;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/signup/prices`,
      "GET",
      null
    );
    setLoadedPrices(responseData.pricesInfo);
  } catch (err) {}
};

interface ToSignupStepTwoHandlerProps extends FetchProps {
  formState: FormHookDispState;
  query: string;
}

export const toSignupStepTwoHandler = async (
  props: ToSignupStepTwoHandlerProps
) => {
  const { formState, sendRequest, query } = props;

  try {
    const eventID = uuidv4();
    const responseData: SignupStepOneInfoResponseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/signup/step-1`,
      "POST",
      JSON.stringify({
        frontId: formState.inputs.frontId?.value || "",
        name: formState.inputs.name.value,
        surname: formState.inputs.surname.value,
        email: formState.inputs.email.value,
        phone: formatPhoneNumber(formState.inputs.phone.value as string),
        query: !!query ? query : "none",
        fbp: readCookie("_fbp"),
        fbc: readCookie("_fbc"),
        srcUrl: window.location.href,
        eventID: eventID,
      }),
      {
        "Content-Type": "application/json",
      },
      true
    );
    localStorage.setItem(
      "signupStepOneInfo",
      JSON.stringify({
        signupStepOneInfo: responseData.signupStepOneInfo,
      })
    );
    // postFbCustomConversionEvent({ eventID, eventname: "AZ_FORM_PASSO_1" });
  } catch (err) {}
};

interface ToSignupStepThreeHandlerProps extends FetchProps {
  formState: FormHookDispState;
  suId: string;
  query: string;
}

export const toSignupStepThreeHandler = async (
  props: ToSignupStepThreeHandlerProps
) => {
  const { formState, sendRequest, suId, query } = props;
  try {
    const responseData: SignupStepTwoInfoResponseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/signup/step-2/${suId}`,
      "POST",
      JSON.stringify({
        postalCode: formState.inputs.postalCode.value,
        uf: formState.inputs.uf.value,
        city: formState.inputs.city.value,
        address: formState.inputs.address.value,
        district: formState.inputs.district.value,
        number: formState.inputs.number.value,
        complement: formState.inputs.complement.value,
        phone: formState.inputs.phone.value,
        query: !!query ? query : "none",
      }),
      {
        "Content-Type": "application/json",
      },
      true
    );
    localStorage.setItem(
      "signupStepTwoInfo",
      JSON.stringify({
        signupStepTwoInfo: responseData.signupStepTwoInfo,
      })
    );
    // postFbCustomConversionEvent({ eventID, eventname: "AZ_FORM_PASSO_2" });
  } catch (err) {}
};

interface FetchPostalCodeInfoProps extends FetchProps {
  postalCode: string;
  setPostalCodeSearchInfo: React.Dispatch<
    React.SetStateAction<PostalCodeResponseData>
  >;
}

export const fetchPostalCodeInfo = async (props: FetchPostalCodeInfoProps) => {
  const { postalCode, setPostalCodeSearchInfo, sendRequest } = props;

  try {
    const responseData: PostalCodeResponseData = await sendRequest(
      `https://viacep.com.br/ws/${postalCode}/json/`,
      "GET",
      null,
      {
        "Content-Type": "application/json",
      },
      false
    );

    if (!!responseData?.logradouro || !!responseData?.ibge) {
      setPostalCodeSearchInfo(responseData);
    }
  } catch (err) {}
};

interface GenerateBillHandlerProps extends FetchProps {
  formState: FormHookDispState;
  suId: string;
  history: History<unknown>;
  query: string;
  planId: StringNumbers;
  discountMultiplier: number | null;
  loadedPrices: SignupPricesAndTaxesData;
}

export const generateBillHandler = async (props: GenerateBillHandlerProps) => {
  const {
    sendRequest,
    formState,
    suId,
    history,
    query,
    planId,
    discountMultiplier,
    loadedPrices,
  } = props;
  try {
    const responseData: SignupStepThreeInfoResponseData = await sendRequest(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/signup/step-3-bill/${planId}/${suId.replace("#finalizar", "")}`,
      "POST",
      JSON.stringify({
        cpfCnpjSwitch: formState.inputs.cpfCnpjSwitch.value as boolean,
        document: formState.inputs.document.value as string,
        coupon: !!discountMultiplier ? formState.inputs.coupon.value : null,
        vendorsAmount:
          planId === "3" ? +formState.inputs.vendorsAmount.value : 1,
        frontPrice: getPlanPriceById(
          planId,
          loadedPrices,
          discountMultiplier,
          +formState?.inputs?.vendorsAmount?.value,
          true
        ),
        query: !!query ? query : "none",
      }),
      {
        "Content-Type": "application/json",
      },
      false
    );
    localStorage.setItem(
      "lastBillData",
      JSON.stringify({
        lastBillData: responseData.billLink,
      })
    );
    localStorage.setItem(
      "signupStepThreeInfo",
      JSON.stringify({
        signupStepThreeInfo: responseData.signupStepThreeInfo,
      })
    );
    history.push(
      `/assinatura/boleto/parabens/${suId.replace(
        "#finalizar",
        ""
      )}?src=${query}`
    );
    // postFbCustomConversionEvent({ eventID, eventname: "AZ_CONV_BOLETO" });
  } catch (err) {}
};

interface FinishAccountForPixHandlerProps extends FetchProps {
  formState: FormHookDispState;
  suId: string;
  history: History<unknown>;
  query: string;
  planId: StringNumbers;
  discountMultiplier: number | null;
  loadedPrices: SignupPricesAndTaxesData;
}

export const finishAccountForPixHandler = async (
  props: FinishAccountForPixHandlerProps
) => {
  const {
    sendRequest,
    formState,
    suId,
    history,
    query,
    planId,
    discountMultiplier,
    loadedPrices,
  } = props;
  try {
    const responseData: SignupStepThreeInfoResponseData = await sendRequest(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/signup/step-3-pix/${planId}/${suId.replace("#finalizar", "")}`,
      "POST",
      JSON.stringify({
        cpfCnpjSwitch: formState.inputs.cpfCnpjSwitch.value as boolean,
        document: formState.inputs.document.value as string,
        coupon: !!discountMultiplier ? formState.inputs.coupon.value : null,
        vendorsAmount:
          planId === "3" ? +formState.inputs.vendorsAmount.value : 1,
        frontPrice: getPlanPriceById(
          planId,
          loadedPrices,
          discountMultiplier,
          +formState?.inputs?.vendorsAmount?.value,
          true
        ),
        query: !!query ? query : "none",
      }),
      {
        "Content-Type": "application/json",
      },
      false
    );
    localStorage.setItem(
      "signupStepThreeInfo",
      JSON.stringify({
        signupStepThreeInfo: responseData.signupStepThreeInfo,
      })
    );
    localStorage.setItem(
      "lastPixQrCode",
      JSON.stringify({
        qrCodeBase64: responseData.qrCodeBase64,
      })
    );
    localStorage.setItem(
      "lastPrice",
      JSON.stringify({
        price: responseData.finalPrice,
      })
    );
    history.push(
      `/assinatura/pix/parabens/${suId.replace("#finalizar", "")}?src=${query}`
    );
    // postFbCustomConversionEvent({ eventID, eventname: "AZ_CONV_PIX" });
  } catch (err) {}
};

interface FinishCardPaymentHandlerProps extends FetchProps {
  formState: FormHookDispState;
  suId: string;
  planId: StringNumbers;
  discountMultiplier: number | null;
  query: string;
  loadedPrices: SignupPricesAndTaxesData;
  isRec: boolean;
}

export const finishCardPaymentHandler = async (
  props: FinishCardPaymentHandlerProps
) => {
  const {
    sendRequest,
    formState,
    suId,
    planId,
    discountMultiplier,
    query,
    loadedPrices,
    isRec,
  } = props;
  try {
    const apiUrl = !isRec
      ? `${process.env.REACT_APP_BACKEND_URL}/signup/step-3-card/${planId}/${suId}`
      : `${process.env.REACT_APP_BACKEND_URL}/signup/step-3-rec/${planId}/${suId}`;

    const responseData: SignupStepThreeInfoResponseData = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        cardNumber: formState.inputs?.cardNumber?.value,
        cardName: formState.inputs?.cardName?.value,
        cardMonth: formState.inputs?.monthValidity?.value,
        cardYear: formState.inputs?.yearValidity?.value,
        cardSecurityCode: formState.inputs?.securityCode?.value,
        cpfCnpjSwitch: formState.inputs?.cpfCnpjSwitch?.value as boolean,
        document: formState.inputs?.document?.value,
        installments: Number(
          formState.inputs?.installments?.value?.toString()?.substr(0, 2)
        ),
        vendorsAmount:
          planId === "3" ? +formState.inputs?.vendorsAmount?.value : 1,
        coupon: !!discountMultiplier ? formState.inputs?.coupon?.value : null,
        frontPrice: !isRec
          ? getPlanPriceById(
              planId,
              loadedPrices,
              discountMultiplier,
              +formState?.inputs?.vendorsAmount?.value,
              true
            )
          : getPlanRecPlanPriceById(
              planId,
              loadedPrices,
              +formState?.inputs?.vendorsAmount?.value || 2,
              discountMultiplier || 1
            ),
        query: !!query ? query : "none",
      }),
      {
        "Content-Type": "application/json",
      },
      true
    );
    localStorage.setItem(
      "signupStepThreeInfo",
      JSON.stringify({
        signupStepThreeInfo: responseData?.signupStepThreeInfo,
      })
    );
  } catch (err) {}
};

interface AboutPageFormHandlerProps extends FetchProps {
  formState: FormHookDispState;
  setVendorPhone?: React.Dispatch<React.SetStateAction<string>>;
  src?: string;
  tag?: string;
  title?: string;
  pipelineId?: number;
}

export const aboutPageFormHandler = async (
  props: AboutPageFormHandlerProps
) => {
  const {
    sendRequest,
    formState,
    setVendorPhone,
    src,
    tag,
    title,
    pipelineId,
  } = props;

  try {
    const responseData: { phone: string; leadId: string } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/users/signup-trial`,
      "POST",
      JSON.stringify({
        name: formState.inputs.name.value,
        email: formState.inputs.email.value,
        phone: formatPhoneNumber(formState.inputs.phone.value as string),
        src: src || "",
        tag: tag || "",
        title: title || "",
        timeWorking: formState.inputs.timeWorking?.value || "",
        salesTeamStatus:
          formState.inputs?.salesTeamStatus?.value || "Sem equipe",
        pipelineId,
      }),
      {
        "Content-Type": "application/json",
      },
      true
    );

    if (setVendorPhone) {
      setVendorPhone(responseData.phone);
    }

    if (responseData.leadId) {
      localStorage.setItem("lastLeadId", responseData.leadId);
    }
  } catch (err) {}
};

interface GetCouponDicountProps extends FetchProps {
  setLoadedDiscountMultiplier: React.Dispatch<React.SetStateAction<number>>;
  setCpMinVendors: React.Dispatch<React.SetStateAction<number>>;
  formState?: FormHookDispState;
  cp?: string;
  setSuccessCoupon?: React.Dispatch<React.SetStateAction<boolean>>;
  setAppliedCoupon?: React.Dispatch<React.SetStateAction<string>>;
}

export const getCouponDicount = async (props: GetCouponDicountProps) => {
  const {
    sendRequest,
    setLoadedDiscountMultiplier,
    formState,
    cp,
    setSuccessCoupon,
    setAppliedCoupon,
    setCpMinVendors,
  } = props;

  try {
    const responseData: {
      discountMultiplier: number;
      minVendors: number;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/signup/coupon-discount`,
      "POST",
      JSON.stringify({
        couponName: !!cp ? cp : formState.inputs.coupon.value,
      }),
      { "Content-Type": "application/json" },
      !setSuccessCoupon ? true : false
    );
    setLoadedDiscountMultiplier(responseData.discountMultiplier);
    setCpMinVendors(responseData.minVendors || 0);
    if (setSuccessCoupon) {
      setSuccessCoupon(true);
    }
    if (setAppliedCoupon) {
      setAppliedCoupon(!!cp ? cp : (formState.inputs.coupon.value as string));
    }
  } catch (err) {}
};

interface RenewWithBillHandlerProps extends FetchProps {
  frontId: string;
  formState: FormHookDispState;
  history: History<unknown>;
  planId: StringNumbers;
  discountMultiplier: number | null;
  auth: AuthContextProps;
  query: string;
  loadedPrices: SignupPricesAndTaxesData;
  isFirstPurchase: boolean;
}

export const renewWithBillHandler = async (
  props: RenewWithBillHandlerProps
) => {
  const {
    frontId,
    sendRequest,
    formState,
    history,
    planId,
    discountMultiplier,
    auth,
    query,
    loadedPrices,
    isFirstPurchase,
  } = props;

  try {
    const responseData: SignupStepThreeInfoResponseData = await sendRequest(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/signup/renovation-bill/${frontId.replace("#finalizar", "")}`,
      "POST",
      JSON.stringify({
        cpfCnpjSwitch: formState.inputs.cpfCnpjSwitch.value as boolean,
        document: formState.inputs.document.value as string,
        coupon: !!discountMultiplier ? formState.inputs.coupon.value : null,
        planId: planId,
        vendorsAmount:
          planId === "3" ? +formState.inputs.vendorsAmount.value : 1,
        frontPrice: getPlanPriceById(
          planId,
          loadedPrices,
          discountMultiplier,
          +formState?.inputs?.vendorsAmount?.value,
          isFirstPurchase
        ),
        query,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      false
    );
    localStorage.setItem(
      "lastBillData",
      JSON.stringify({
        lastBillData: responseData.billLink,
      })
    );
    localStorage.setItem(
      "signupStepThreeInfo",
      JSON.stringify({
        signupStepThreeInfo: responseData.signupStepThreeInfo,
      })
    );

    history.push(
      `/assinatura/boleto/parabens/${frontId.replace(
        "#finalizar",
        ""
      )}?src=renovacao`
    );
  } catch (err) {}
};

interface RenewWithPixHandlerProps extends FetchProps {
  frontId: string;
  formState: FormHookDispState;
  history: History<unknown>;
  planId: StringNumbers;
  discountMultiplier: number | null;
  auth: AuthContextProps;
  query: string;
  loadedPrices: SignupPricesAndTaxesData;
  isFirstPurchase: boolean;
}

export const renewWithPixHandler = async (props: RenewWithPixHandlerProps) => {
  const {
    sendRequest,
    formState,
    frontId,
    history,
    planId,
    discountMultiplier,
    auth,
    query,
    loadedPrices,
    isFirstPurchase,
  } = props;

  try {
    const responseData: SignupStepThreeInfoResponseData = await sendRequest(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/signup/renovation-pix/${frontId.replace("#finalizar", "")}`,
      "POST",
      JSON.stringify({
        cpfCnpjSwitch: formState.inputs.cpfCnpjSwitch.value as boolean,
        document: formState.inputs.document.value as string,
        coupon: !!discountMultiplier ? formState.inputs.coupon.value : null,
        planId: planId,
        vendorsAmount:
          planId === "3" ? +formState.inputs.vendorsAmount.value : 1,
        frontPrice: getPlanPriceById(
          planId,
          loadedPrices,
          discountMultiplier,
          +formState?.inputs?.vendorsAmount?.value,
          isFirstPurchase
        ),
        query,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      false
    );
    localStorage.setItem(
      "signupStepThreeInfo",
      JSON.stringify({
        signupStepThreeInfo: responseData.signupStepThreeInfo,
      })
    );
    localStorage.setItem(
      "lastPixQrCode",
      JSON.stringify({
        qrCodeBase64: responseData.qrCodeBase64,
      })
    );
    localStorage.setItem(
      "lastPrice",
      JSON.stringify({
        price: responseData.finalPrice,
      })
    );

    history.push(
      `/assinatura/pix/parabens/${frontId.replace(
        "#finalizar",
        ""
      )}?src=renovacao`
    );
  } catch (err) {}
};

interface RenewWithCardProps extends FetchProps {
  formState: FormHookDispState;
  frontId: string;
  planId: StringNumbers;
  discountMultiplier: number | null;
  auth: AuthContextProps;
  history: History<unknown>;
  query: string;
  loadedPrices: SignupPricesAndTaxesData;
  isFirstPurchase: boolean;
}

export const renewWithCard = async (props: RenewWithCardProps) => {
  const {
    sendRequest,
    formState,
    frontId,
    planId,
    discountMultiplier,
    auth,
    history,
    query,
    loadedPrices,
    isFirstPurchase,
  } = props;

  try {
    const responseData: SignupStepThreeInfoResponseData = await sendRequest(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/signup/renovation-card/${frontId.replace("#finalizar", "")}`,
      "POST",
      JSON.stringify({
        planId: planId,
        cardNumber: formState.inputs.cardNumber.value,
        cardName: formState.inputs.cardName.value,
        cardMonth: formState.inputs.monthValidity.value,
        cardYear: formState.inputs.yearValidity.value,
        cardSecurityCode: formState.inputs.securityCode.value,
        cpfCnpjSwitch: formState.inputs.cpfCnpjSwitch.value as boolean,
        document: formState.inputs.document.value,
        installments: Number(
          formState.inputs.installments.value.toString().substr(0, 2)
        ),
        coupon: !!discountMultiplier ? formState.inputs.coupon.value : null,
        vendorsAmount:
          planId === "3" ? +formState.inputs.vendorsAmount.value : 1,
        frontPrice: getPlanPriceById(
          planId,
          loadedPrices,
          discountMultiplier,
          +formState?.inputs?.vendorsAmount?.value,
          isFirstPurchase
        ),
        query,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
    localStorage.setItem(
      "signupStepThreeInfo",
      JSON.stringify({
        signupStepThreeInfo: responseData.signupStepThreeInfo,
      })
    );

    history.push(
      `/assinatura/parabens/${planId}/${frontId.replace(
        "#finalizar",
        ""
      )}?src=renovacao`
    );
  } catch (err) {}
};

interface PostPageviewProps extends FetchProps {
  eventName: string;
}

export const postPageview = async (props: PostPageviewProps) => {
  const { sendRequest, eventName } = props;
  const eventID = uuidv4();
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/signup/page-view`,
      "POST",
      JSON.stringify({
        eventName,
        fbp: readCookie("_fbp"),
        fbc: readCookie("_fbc"),
        srcUrl: window.location.href,
        eventID: eventID,
      }),
      { "Content-Type": "application/json" },
      false
    );
    // postFbCustomConversionEvent({ eventID, eventname: eventName });
  } catch (err) {}
};

interface UpgradeWithBillHandlerProps extends FetchProps {
  frontId: string;
  formState: FormHookDispState;
  history: History<unknown>;
  discountMultiplier: number | null;
  auth: AuthContextProps;
  query: string;
  loadedPrices: SignupPricesAndTaxesData;
  validityDate: string;
  currentVendors: number;
}

export const upgradeWithBillHandler = async (
  props: UpgradeWithBillHandlerProps
) => {
  const {
    frontId,
    sendRequest,
    formState,
    history,
    discountMultiplier,
    auth,
    query,
    loadedPrices,
    validityDate,
    currentVendors,
  } = props;

  try {
    const responseData: SignupStepThreeInfoResponseData = await sendRequest(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/signup/upgrade-bill/${frontId.replace("#finalizar", "")}`,
      "POST",
      JSON.stringify({
        cpfCnpjSwitch: formState.inputs.cpfCnpjSwitch.value as boolean,
        document: formState.inputs.document.value as string,
        coupon: !!discountMultiplier ? formState.inputs.coupon.value : null,
        vendorsAmount: +formState.inputs.vendorsAmount.value,
        frontPrice: getPlanPriceById(
          "3",
          loadedPrices,
          discountMultiplier,
          +formState?.inputs?.vendorsAmount?.value,
          false,
          true,
          validityDate,
          currentVendors
        ),
        query,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      false
    );
    localStorage.setItem(
      "lastBillData",
      JSON.stringify({
        lastBillData: responseData.billLink,
      })
    );
    localStorage.setItem(
      "signupStepThreeInfo",
      JSON.stringify({
        signupStepThreeInfo: responseData.signupStepThreeInfo,
      })
    );

    history.push(
      `/assinatura/boleto/parabens/${frontId.replace(
        "#finalizar",
        ""
      )}?src=renovacao`
    );
  } catch (err) {}
};

interface UpgradeWithPixHandlerProps extends FetchProps {
  frontId: string;
  formState: FormHookDispState;
  history: History<unknown>;
  discountMultiplier: number | null;
  auth: AuthContextProps;
  query: string;
  loadedPrices: SignupPricesAndTaxesData;
  validityDate: string;
  currentVendors: number;
}

export const upgradeWithPixHandler = async (
  props: UpgradeWithPixHandlerProps
) => {
  const {
    sendRequest,
    formState,
    frontId,
    history,
    discountMultiplier,
    auth,
    query,
    loadedPrices,
    validityDate,
    currentVendors,
  } = props;

  try {
    const responseData: SignupStepThreeInfoResponseData = await sendRequest(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/signup/upgrade-pix/${frontId.replace("#finalizar", "")}`,
      "POST",
      JSON.stringify({
        cpfCnpjSwitch: formState.inputs.cpfCnpjSwitch.value as boolean,
        document: formState.inputs.document.value as string,
        coupon: !!discountMultiplier ? formState.inputs.coupon.value : null,
        vendorsAmount: +formState.inputs.vendorsAmount.value,
        frontPrice: getPlanPriceById(
          "3",
          loadedPrices,
          discountMultiplier,
          +formState?.inputs?.vendorsAmount?.value,
          false,
          true,
          validityDate,
          currentVendors
        ),
        query,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      false
    );
    localStorage.setItem(
      "signupStepThreeInfo",
      JSON.stringify({
        signupStepThreeInfo: responseData.signupStepThreeInfo,
      })
    );
    localStorage.setItem(
      "lastPixQrCode",
      JSON.stringify({
        qrCodeBase64: responseData.qrCodeBase64,
      })
    );
    localStorage.setItem(
      "lastPrice",
      JSON.stringify({
        price: responseData.finalPrice,
      })
    );
    history.push(
      `/assinatura/pix/parabens/${frontId.replace(
        "#finalizar",
        ""
      )}?src=renovacao`
    );
  } catch (err) {}
};

interface UpgradeWithCardProps extends FetchProps {
  formState: FormHookDispState;
  frontId: string;
  discountMultiplier: number | null;
  auth: AuthContextProps;
  history: History<unknown>;
  query: string;
  loadedPrices: SignupPricesAndTaxesData;
  validityDate: string;
  currentVendors: number;
}

export const upgradeWithCard = async (props: UpgradeWithCardProps) => {
  const {
    sendRequest,
    formState,
    frontId,
    discountMultiplier,
    auth,
    history,
    query,
    loadedPrices,
    validityDate,
    currentVendors,
  } = props;

  try {
    const responseData: SignupStepThreeInfoResponseData = await sendRequest(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/signup/upgrade-card/${frontId.replace("#finalizar", "")}`,
      "POST",
      JSON.stringify({
        cardNumber: formState.inputs.cardNumber.value,
        cardName: formState.inputs.cardName.value,
        cardMonth: formState.inputs.monthValidity.value,
        cardYear: formState.inputs.yearValidity.value,
        cardSecurityCode: formState.inputs.securityCode.value,
        cpfCnpjSwitch: formState.inputs.cpfCnpjSwitch.value as boolean,
        document: formState.inputs.document.value,
        installments: Number(
          formState.inputs.installments.value.toString().substr(0, 2)
        ),
        coupon: !!discountMultiplier ? formState.inputs.coupon.value : null,
        vendorsAmount: +formState.inputs.vendorsAmount.value,
        frontPrice: getPlanPriceById(
          "3",
          loadedPrices,
          discountMultiplier,
          +formState?.inputs?.vendorsAmount?.value,
          false,
          true,
          validityDate,
          currentVendors
        ),
        query,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
    localStorage.setItem(
      "signupStepThreeInfo",
      JSON.stringify({
        signupStepThreeInfo: responseData.signupStepThreeInfo,
      })
    );

    history.push(
      `/assinatura/parabens/3/${frontId.replace(
        "#finalizar",
        ""
      )}?src=renovacao`
    );
  } catch (err) {}
};

// CHANGE SUB CARD //////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
interface ChangeSubCardProps extends FetchProps {
  formState: FormHookDispState;
  auth: AuthContextProps;
}

export const changeSubCard = async (props: ChangeSubCardProps) => {
  const { sendRequest, formState, auth } = props;

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/signup/signature/card/${auth.userId}`,
      "PUT",
      JSON.stringify({
        cardNumber: formState.inputs.cardNumber.value,
        cardName: formState.inputs.cardName.value,
        cardMonth: formState.inputs.monthValidity.value,
        cardYear: formState.inputs.yearValidity.value,
        cardSecurityCode: formState.inputs.securityCode.value,
        document: formState.inputs.document.value,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );
  } catch (err) {}
};

// NEW ADDON //////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
interface NewAddonWithCardProps extends FetchProps {
  formState: FormHookDispState;
  discountMultiplier: number | null;
  auth: AuthContextProps;
  finalPrice: number;
  addonType: AddonType;
  addonSubType: AddonOmnichannelType;
}

export const newAddonWithCard = async (props: NewAddonWithCardProps) => {
  return new Promise<NewAddonResponse>(async (resolve, reject) => {
    const {
      sendRequest,
      formState,
      discountMultiplier,
      auth,
      finalPrice,
      addonType,
      addonSubType,
    } = props;

    try {
      const response: NewAddonResponse = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/signup/addon/${addonType}/${addonSubType}`,
        "POST",
        JSON.stringify({
          cardNumber: formState.inputs.cardNumber.value,
          cardName: formState.inputs.cardName.value,
          cardMonth: formState.inputs.monthValidity.value,
          cardYear: formState.inputs.yearValidity.value,
          cardSecurityCode: formState.inputs.securityCode.value,
          cpfCnpjSwitch: formState.inputs.cpfCnpjSwitch.value as boolean,
          document: formState.inputs.document.value,
          accountsAmount: +formState.inputs.accountsAmount.value,
          frontPrice: finalPrice,
          coupon: !!discountMultiplier ? formState.inputs.coupon.value : null,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        true
      );

      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

// GET ADDON STATUS //////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
interface GetAddonStatusProps extends FetchProps {
  auth: AuthContextProps;
  addonType: AddonType;
  addonId: string;
}

export const getAddonStatus = async (props: GetAddonStatusProps) => {
  return new Promise<GetAddonStatusResponse>(async (resolve, reject) => {
    const { sendRequest, auth, addonType, addonId } = props;

    try {
      const response: GetAddonStatusResponse = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/signup/addon/${addonType}/${addonId}/status`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        false
      );

      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

// CHANGE ADDON CARD //////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
interface ChangeAddonCardProps extends FetchProps {
  formState: FormHookDispState;
  auth: AuthContextProps;
  addonType: AddonType;
  addonId: string;
}

export const changeAddonCard = async (props: ChangeAddonCardProps) => {
  return new Promise<NewAddonResponse>(async (resolve, reject) => {
    const { sendRequest, formState, auth, addonType, addonId } = props;

    try {
      const response: NewAddonResponse = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/signup/addon/${addonType}/${addonId}/card`,
        "PUT",
        JSON.stringify({
          cardNumber: formState.inputs.cardNumber.value,
          cardName: formState.inputs.cardName.value,
          cardMonth: formState.inputs.monthValidity.value,
          cardYear: formState.inputs.yearValidity.value,
          cardSecurityCode: formState.inputs.securityCode.value,
          cpfCnpjSwitch: formState.inputs.cpfCnpjSwitch.value as boolean,
          document: formState.inputs.document.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        true
      );

      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

// CHANGE ADDON PRICE //////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
interface ChangeAddonPriceProps extends FetchProps {
  formState: FormHookDispState;
  discountMultiplier: number | null;
  auth: AuthContextProps;
  finalPrice: number;
  addonType: AddonType;
  addonId: string;
}

export const changeAddonPrice = async (props: ChangeAddonPriceProps) => {
  return new Promise<NewAddonResponse>(async (resolve, reject) => {
    const {
      sendRequest,
      formState,
      discountMultiplier,
      auth,
      finalPrice,
      addonType,
      addonId,
    } = props;

    try {
      const response: NewAddonResponse = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/signup/addon/${addonType}/${addonId}/price`,
        "PUT",
        JSON.stringify({
          accountsAmount: +formState.inputs.accountsAmount.value,
          frontPrice: finalPrice,
          coupon: !!discountMultiplier ? formState.inputs.coupon.value : null,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        true
      );

      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};
