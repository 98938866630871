import { dateFormat } from "../util/dateFormat";
import {
  AzumeModules,
  AzumeModulesQueryParamIds,
  WebhookAction,
  WppAccountStatus,
  BTGStatus,
  MarketplaceSuppliers,
} from "./types";

export const APP_VERSION = "10.43.102";

export const SUPPORTED_CHROME_VERSION = 108;
export const SUPPORTED_CHROME_MOBILE_VERSION = 0;
export const KANBAN_MAX_COLUMN_SALES = 8;

export const INTERNAL_LEAD_ACCOUNTS = [
  "5fb437461b1f5010800805af",
  "624475fe8517a70016326a1d",
];

export const CONTACT_TYPES = ["WhatsApp", "Ligação"];

//
export const PRICING_COMPONENTS_NAMES = [
  "Valor Fixo",
  "Percentual sobre Valor Final",
  "Percentual sobre Serviço",
  "Percentual sobre Kit",
  "Percentual sobre Lucro",
  "Valor por kWp",
  "Valor por Módulo",
  "Valor por Inversor",
  "Valor por km",
  "Comissão",
  "Comissão Individual",
  "Margem de Negociação",
];

export const PRICING_COMISSION_PERMISSIONS = [
  { id: "NONE", txt: "Nenhuma" },
  { id: "VIEW", txt: "Visualização" },
  { id: "EDIT", txt: "Edição" },
];

export const PRICING_COMPONENTS_RANGES = [
  { id: "KWP", txt: "kWp (potência dos módulos)" },
  { id: "MODULES", txt: "Quantidade de módulos" },
  { id: "INVERTERS", txt: "Quantidade de inversores" },
  { id: "KW", txt: "kW (potência dos inversores)" },
  { id: "KM", txt: "km (distância)" },
  { id: "ROOF_HIGHT", txt: "Altura do Telhado (m)" },
];

export const PRICING_COMPONENTS_CONDITIONALS = [
  { id: "NONE", txt: "Nenhuma" },
  { id: "VOLTAGE", txt: "Tensão de Saída" },
  { id: "FIXATION", txt: "Tipo de Fixação" },
  { id: "INVERTER_TYPE", txt: "Tipo de Inversor" },
  { id: "VENDOR", txt: "Vendedor" },
  { id: "PRE_VENDOR", txt: "Pré-Vendedor" },
];

export const PRICING_COMPONENTS_COND_LOGIC = [
  { id: "EQUAL", txt: "É igual a" },
  { id: "DIFFERENT", txt: "É diferente de" },
];

export const TIME_WORKING = [
  "Ainda não trabalho",
  "Trabalho há menos de 1 ano",
  "Trabalho há mais de 1 ano",
];

export const SALES_TEAM_STATUS = [
  "Sou autônomo",
  "1 vendedor",
  "De 2 a 4 vendedores",
  "Mais de 4 vendedores",
];

export const PLAN_OPTIONS: ["Profissional", "Empresa"] = [
  "Profissional",
  "Empresa",
];

export const UF: [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO"
] = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

export const REASONS: [
  "NÃO VALE O QUE PAGUEI",
  "NÃO CONSEGUI USAR DIREITO",
  "EXISTE UM CONCORRENTE MELHOR",
  "PREFIRO UTILIZAR UMA TABELA",
  "CLIENTES NÃO GOSTARAM",
  "NÃO GOSTEI DA PROPOSTA",
  "ACHEI QUE TINHA OFF-GRID",
  "O CRM NÃO É BOM",
  "SÓ QUERIA FAZER O TESTE GRÁTIS",
  "BAIXA DEMANDA POR PROPOSTAS",
  "PAREI DE TRABALHAR NO SETOR",
  "NÃO CONSIGO PAGAR AGORA",
  "O APP NÃO FUNCIONA",
  "ATENDIMENTO RUIM",
  "OUTRO MOTIVO"
] = [
  "NÃO VALE O QUE PAGUEI",
  "NÃO CONSEGUI USAR DIREITO",
  "EXISTE UM CONCORRENTE MELHOR",
  "PREFIRO UTILIZAR UMA TABELA",
  "CLIENTES NÃO GOSTARAM",
  "NÃO GOSTEI DA PROPOSTA",
  "ACHEI QUE TINHA OFF-GRID",
  "O CRM NÃO É BOM",
  "SÓ QUERIA FAZER O TESTE GRÁTIS",
  "BAIXA DEMANDA POR PROPOSTAS",
  "PAREI DE TRABALHAR NO SETOR",
  "NÃO CONSIGO PAGAR AGORA",
  "O APP NÃO FUNCIONA",
  "ATENDIMENTO RUIM",
  "OUTRO MOTIVO",
];

export const REASONS_COMEBACK: [
  "MELHOR ATENDIMENTO",
  "UMA SITUAÇÃO FINANCEIRA MELHOR",
  "MAIS DEMANDA POR PROPOSTAS",
  "PROPOSTA OFF-GRID",
  "PROPOSTAS MAIS PERSONALIZADAS",
  "MELHORIAS NO CRM",
  "MELHOR VERSÃO PDF DA PROPOSTA",
  "OUTRAS FUNCIONALIDADES",
  "NÃO VOLTARIA A USAR"
] = [
  "MELHOR ATENDIMENTO",
  "UMA SITUAÇÃO FINANCEIRA MELHOR",
  "MAIS DEMANDA POR PROPOSTAS",
  "PROPOSTA OFF-GRID",
  "PROPOSTAS MAIS PERSONALIZADAS",
  "MELHORIAS NO CRM",
  "MELHOR VERSÃO PDF DA PROPOSTA",
  "OUTRAS FUNCIONALIDADES",
  "NÃO VOLTARIA A USAR",
];

export const MONTHS: [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12"
] = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

export const INSTALLMENTS_SERRANA: [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12"
] = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

export const INTALLMENTS_CREDSOLARIS = [
  "12x",
  "24x",
  "36x",
  "48x",
  "60x",
  "72x",
  "84x",
  "96x",
  "108x",
  "120x",
];

export const NET_CLASS_LIST: ["Trifásica", "Bifásica", "Monofásica"] = [
  "Trifásica",
  "Bifásica",
  "Monofásica",
];

export const TAFIFF_MODALITIES: [
  "Grupo B (Baixa Tensão)",
  "Grupo A (Alta Tensão)"
] = ["Grupo B (Baixa Tensão)", "Grupo A (Alta Tensão)"];

export const TARIFF_CLASS: ["Azul", "Verde"] = ["Azul", "Verde"];

export const DIMENSIONING_TYPE: ["Consumo", "Demanda"] = ["Consumo", "Demanda"];

export const MONITORING_OPTIONS: ["Wi-Fi", "4g", "Nenhum"] = [
  "Wi-Fi",
  "4g",
  "Nenhum",
];

export const AZUME_MODULES: AzumeModules[] = [
  "Proposta 2.0",
  "Proposta Personalizada",
  "Assinatura Digital",
  "Gerador de Propostas de Pós-Venda",
  "Implementação Website",
  "Implementação Domínio",
  "Implementação em Grupo Conta",
  "Implementação Exclusiva Conta",
];

export const AZUME_COURSES = [
  "Energia Solar Lucrativa 4.0",
  "Curso de Instalação Presencial",
  "Projetista Fotovoltaico 4.0",
  "Vendedor 4.0",
  "Manutenção de Sistemas Fotovoltaicos",
  "Eletricista Solar",
  "NR-10, NR-35 e outras NRs",
];

export const FIXATION_BEDIN = [
  // Cada fornecedor trabalha com um conjunto de estruturar de fixação. É necessário verificar quais elas são e listá-las aqui
  "ALUZINCO TRAPEZOIDAL",
  "TELHA FIBROCIMENTO - MADEIRA",
  "TELHA FIBROCIMENTO - METAL",
  "TELHA CERÂMICA",
  "SOLO - MONOPOSTE",
  "SOLO - BIPOSTE",
  "TELHA ZIPADA",
  "SEM ESTRUTURA",
];

export const FIXATION_ALDO = [
  "Cerâmica",
  "Metálica Trapezoidal",
  "Metálica Ondulada",
  "Fibrocimento Est. Madeira",
  "Fibrocimento Est. Metálica",
  "Laje",
  "Solo",
  "Sem Estrutura",
];

export const FIXATION_ECORI = [
  "Cerâmica",
  "Metálica Trapezoidal",
  "Metálica Ondulada",
  "Fibrocimento Est. Madeira",
  "Fibrocimento Est. Metálica",
  "Laje",
  "Sem Estrutura",
];

export const FIXATION_SERRANA = [
  "Cerâmica",
  "Metálica",
  "Fibrocimento",
  "Laje",
  "Solo",
  "Mini Trilho",
  // "Kalhetão",
  "Americano",
];

export const FIXATION_SOU_ENERGY = [
  "Cerâmica",
  "Metálica",
  "Fibrocimento",
  "Laje",
  "Solo",
  "Mini Trilho",
  "Sem Estrutura",
];

export const FIXATION_SOLAR_INOVE = [
  "Cerâmica",
  "Metálica Trapezoidal",
  "Metálica Ondulada",
  "Fibrocimento",
  "Laje",
  "Sem Estrutura",
];

export const FIXATION_EDELTEC = [
  "Cerâmica",
  "Metálica Trapezoidal",
  "Fibrocimento Est. Madeira",
  "Fibrocimento Est. Metálica",
  "Laje",
  "Solo",
  "Sem Estrutura",
];

export const FIXATION_PHB = [
  "Telhado",
  "Solo",
  "Solo Monoposte",
  "Laje",
  "Carport",
];

export const FIXATION_HELTE = [
  "Cerâmica",
  "Metálica Trapezoidal",
  "Metálica 55 cm",
  "Laje",
  "Solo",
  "Fibrocimento",
  "Sem Estrutura",
];

export const FIXATION_FORTLEV = [
  "Telhado Cerâmico",
  "Telhado Fibrocimento - Estrutura em madeira (200mm)",
  "Telhado Fibrocimento - Estrutura em madeira (300mm)",
  "Telhado Fibrocimento - Estrutura em metal (250mm)",
  "Telhado Shingle",
  "Laje",
  "Telhado zipado",
  "Solo - Lastro Fortlev Solar",
  "Solo Fixo - Estrutura metálica",
  "Telhado Metálico - Mini trilho (0.5m)",
];

export const FIXATION = [
  "Cerâmica",
  "Metálica Trapezoidal",
  "Metálica Ondulada",
  "Fibrocimento Est. Madeira",
  "Fibrocimento Est. Metálica",
  "Laje",
  "Solo",
  "Mini Trilho",
  "Kalhetão",
  "Americano",
  "Carport",
  "Sem Estrutura",
];

export const FIXATION_ICONS = [
  { value: "Cerâmica", icon: "azume-prod-est-ceramica.png" },
  {
    value: "Fibrocimento Est. Madeira",
    icon: "azume-prod-est-fibrocimento-madeira.png",
  },
  { value: "Mini Trilho", icon: "azume-prod-est-mini-trilho.png" },
  {
    value: "Fibrocimento Est. Metálica",
    icon: "azume-prod-est-fibrocimento-metal.png",
  },
  {
    value: "Metálica Trapezoidal",
    icon: "azume-prod-est-metalica-trapezoidal.png",
  },
  { value: "Laje", icon: "azume-prod-est-laje.png" },
  { value: "Solo", icon: "azume-prod-est-solo.png" },
  { value: "Americano", icon: "azume-prod-est-americano.png" },
  { value: "Sem Estrutura", icon: "azume-prod-est-sem-estrutura.png" },
];

export const PHASES_ICONS = [
  { value: "Sem Filtro", icon: "azume-prod-fase-todas.png" },
  { value: "Monofásico", icon: "azume-prod-fases-mono.png" },
  {
    value: "Trifásico",
    icon: "azume-prod-fases-tri.png",
  },
];

export const INVERTER_TYPE_ICONS = [
  { value: "Sem Filtro", icon: "azume-prod-inversor-todos.png" },
  { value: "Inversor de String", icon: "azume-prod-inversor-string.png" },
  {
    value: "Microinversor",
    icon: "azume-prod-inversor-micro.png",
  },
];

export const VOLTAGE_ICONS = [
  { value: "Sem Filtro", icon: "azume-prod-tensao-todas.png" },
  { value: "220V", icon: "azume-prod-tensao-220v.png" },
  {
    value: "380V",
    icon: "azume-prod-tensao-380v.png",
  },
];

export const VOLTAGE_PHB = [
  "127 V",
  "220 V - Monofásico",
  "220 V - Trifásico",
  "380 V",
];

export const VOLTAGE_FORTLEV = [
  "220 V - Monofásico",
  "220 V - Bifásico",
  "220 V - Trifásico",
  "380 V",
];

export const VOLTAGE = ["127 V", "220 V", "380 V"];

export const MENUS = [
  "CLIENTES",
  "PROPOSTAS",
  "FINANCIAMENTOS",
  "PEDIDOS",
  "PRÉ-VENDA",
  "VENDAS",
  "ADMINISTRATIVO",
  "PROJETOS",
  "OBRAS",
  "PÓS-VENDA",
  "CHATS",
  "AGENDA",
  "KITS",
  "EQUIPAMENTOS",
  "COLABORADORES",
  "WEBSITE",
  "CONTRATOS",
  "IMPORTAÇÕES",
];

export const SUPPLIER = [
  // "AZUME",
  "ALDO",
  "ECORI",
  "SERRANA",
  "SOU ENERGY",
  "FORTLEV",
  "EDELTEC",
  "HELTE",
  "PHB",
  // "BEDIN SOLAR",
  // "SOLAR INOVE",
];

export const SUPPLIER_ICONS = [
  // { value: "AZUME", icon: "avatar-kit-azume.png" },
  { value: "ALDO", icon: "avatar-aldo.png" },
  { value: "ECORI", icon: "avatar-ecori.png" },
  { value: "SERRANA", icon: "avatar-serrana.png" },
  { value: "SOU ENERGY", icon: "avatar-sou-energy.png" },
  { value: "FORTLEV", icon: "avatar-fortlev.png" },
  { value: "EDELTEC", icon: "avatar-edeltec.png" },
  { value: "HELTE", icon: "avatar-helte.png" },
  { value: "PHB", icon: "avatar-phb.png" },
  // { value: "BEDIN SOLAR", icon: "avatar-bedin-solar.png" },
  // { value: "SOLAR INOVE", icon: "avatar-solar-inove.png" },
];

export const SUPPLIER_CNPJ = [
  // { value: "AZUME", cnpj: "05.262.518/0001-17" },
  { value: "ALDO", cnpj: "81.106.957/0001-19" },
  { value: "ECORI", cnpj: "13.329.758/0001-10" },
  { value: "SERRANA", cnpj: "05.262.518/0001-17" },
  { value: "SOU ENERGY", cnpj: "27.568.657/0001-06" },
  { value: "FORTLEV", cnpj: "32.270.838/0001-91" },
  { value: "EDELTEC", cnpj: "10.289.713/0001-70" },
  { value: "HELTE", cnpj: "31.088.569/0001-84" },
  { value: "PHB", cnpj: "47.178.240/0001-44" },
  { value: "BEDIN SOLAR", cnpj: "82.489.709/0001-67" },
  { value: "BELENERGY", cnpj: "05.151.518/0001-40" },
  { value: "FOTUS", cnpj: "07.117.654/0001-49" },
  { value: "SOLAR INOVE", cnpj: "01.855.226/0010-28" },
];

export const MARKETPLACE_SUPPLIERS: MarketplaceSuppliers[] = ["SERRANA"];

export const PAYMENT_STEPS: ["Conta", "Assinar"] = ["Conta", "Assinar"];

export const NOTE_CALENDAR: ["TODAS AS NOTAS", "NÃO AGENDADAS", "AGENDADAS"] = [
  "TODAS AS NOTAS",
  "NÃO AGENDADAS",
  "AGENDADAS",
];

export const KANBAN_TYPES: [
  "SALES",
  "PROJECT",
  "INSTALLATION",
  "AFTER_SALES",
  "PRE_SALES",
  "ADMINISTRATIVE"
] = [
  "SALES",
  "PROJECT",
  "INSTALLATION",
  "AFTER_SALES",
  "PRE_SALES",
  "ADMINISTRATIVE",
];

export const KANBAN_TYPES_WITH_IDS = [
  {
    txt: "VENDAS",
    id: "SALES",
  },
  {
    txt: "PROJETOS",
    id: "PROJECT",
  },
  {
    txt: "OBRAS",
    id: "INSTALLATION",
  },
  {
    txt: "PÓS-VENDA",
    id: "AFTER_SALES",
  },
  {
    txt: "PRÉ-VENDA",
    id: "PRE_SALES",
  },
  {
    txt: "ADMINISTRATIVO",
    id: "ADMINISTRATIVE",
  },
];

export const WEBHOOK_ACTIONS_WITH_IDS = [
  {
    txt: "Movimentação de um negócio entre etapas ",
    id: WebhookAction.MOVING_BUSINESS_BETWEEN_STAGES,
  },
  {
    txt: "Negócio ganho",
    id: WebhookAction.BUSINESS_GAIN,
  },
  {
    txt: "Negócio perdido",
    id: WebhookAction.BUSINESS_LOST,
  },
  {
    txt: "Retorno do negócio ao funil",
    id: WebhookAction.BUSINESS_RETURN_TO_FUNNEL,
  },
  {
    txt: "Criação de uma nota agendada",
    id: WebhookAction.SCHEDULED_NOTE_CREATION,
  },
];

export const WPP_ACCOUNT_STATUS_WITH_IDS = [
  {
    color: "#ca1a1a",
    txt: "Desconectado",
    id: WppAccountStatus.DISCONNECTED,
  },
  {
    color: "#139e5a",
    txt: "Conectado",
    id: WppAccountStatus.CONNECTED,
  },
];

export const TAGS_LIST_CUSTOMER = [
  { tag: "[[ NOME_CLIENTE ]]", description: "Nome do Cliente" },
  { tag: "[[ UF_CLIENTE ]]", description: "UF do Cliente" },
  { tag: "[[ CIDADE_CLIENTE ]]", description: "Cidade do Cliente" },
  { tag: "[[ EMAIL_CLIENTE ]]", description: "E-mail do Cliente" },
  { tag: "[[ TELEFONE_CLIENTE ]]", description: "Telefone do Cliente" },
  { tag: "[[ WHATSAPP_CLIENTE ]]", description: "WhatsApp do Cliente" },
  { tag: "[[ DOCUMENTO_CLIENTE ]]", description: "CPF/CNPJ do Cliente" },
  { tag: "[[ ENDERECO_CLIENTE ]]", description: "Endereço do Cliente" },
  {
    tag: "[[ RG_CLIENTE ]]",
    description: "RG do cliente (ou responsável)",
  },
  {
    tag: "[[ NAC_CLIENTE ]]",
    description: "Nacionalidade do cliente (ou responsável)",
  },
  {
    tag: "[[ PROF_CLIENTE ]]",
    description: "Profissão do cliente (ou responsável)",
  },
  {
    tag: "[[ NOME_RESP_CLIENTE ]]",
    description: "Nome do Responsável Pela Empresa (cliente)",
  },
  {
    tag: "[[ CPF_RESP_CLIENTE ]]",
    description: "CPF do Responsável Pela Empresa (cliente)",
  },
  {
    tag: "[[ OBSERVACOES_CLIENTE ]]",
    description: "Obersavações do Cliente",
  },
];

export const TAGS_LIST_MY_COMPANY = [
  { tag: "[[ NOME_EMPRESA ]]", description: "Nome da Minha Empresa" },
  { tag: "[[ CNPJ_EMPRESA ]]", description: "CNPJ da Minha Empresa" },
  {
    tag: "[[ NOME_RESP_EMPRESA ]]",
    description: "Nome do Responsável pela Minha Empresa",
  },
  {
    tag: "[[ CPF_RESP_EMPRESA ]]",
    description: "CPF do Responsável pela Minha Empresa",
  },
  {
    tag: "[[ RG_RESP_EMPRESA ]]",
    description: "RG do Responsável pela Minha Empresa",
  },
  {
    tag: "[[ PROF_RESP_EMPRESA ]]",
    description: "Profissão do Responsável pela Minha Empresa",
  },
  {
    tag: "[[ NAC_RESP_EMPRESA ]]",
    description: "Nacionalidade do Responsável pela Minha Empresa",
  },
  { tag: "[[ ENDERECO_EMPRESA ]]", description: "Endereço da Minha Empresa" },
  { tag: "[[ TELEFONE_EMPRESA ]]", description: "Telefone da Minha Empresa" },
  { tag: "[[ WHATSAPP_EMPRESA ]]", description: "WhatsApp da Minha Empresa" },
];

export const TAGS_LIST_SALE_AND_SYSTEM = [
  { tag: "[[ VALOR_VENDA ]]", description: "Valor de Venda" },
  { tag: "[[ POTENCIA_SISTEMA ]]", description: "Potência do Sistema" },
  {
    tag: "[[ CONDICOES_DE_PAGAMENTO ]]",
    description: "Condições de Pagamento do Sistema",
  },
  { tag: "[[ VALIDADE_PROPOSTA ]]", description: "Validade da Proposta" },
];

export const TAGS_LIST_OTHER = [
  {
    tag: "[[ N_CONTRATO ]]",
    description: "Número do Contrato",
  },
  {
    tag: "[[ DATA_DO_DIA ]]",
    description: "Data do Dia da Geração do Contrato",
  },
];

export const CHECKLIST_ITEMS_TYPES = [
  { txt: "Checkbox", id: "CHECKBOX" },
  { txt: "Texto", id: "TEXT" },
  { txt: "Número", id: "NUMBER" },
  { txt: "Lista", id: "LIST" },
];

export const BTG_STATUS_MAP: {
  status: BTGStatus;
  color:
    | "positive_light"
    | "positive_dark"
    | "negative"
    | "warning"
    | "neutral";
  txt: string;
}[] = [
  {
    status: "SIMULAÇÃO_GERADA",
    color: "neutral",
    txt: "Simulação",
  },
  {
    status: "SOLICITACAO_GERADA",
    color: "neutral",
    txt: "Solicitação Gerada",
  },
  {
    status: "FORMULARIO_PREENCHIMENTO",
    color: "positive_dark",
    txt: "Formulário em Preenchimento",
  },
  {
    status: "EM_ANALISE",
    color: "positive_dark",
    txt: "Em Análise de Crédito",
  },
  {
    status: "EM_REANALISE",
    color: "positive_dark",
    txt: "Em Reanálise de Crédito",
  },
  {
    status: "REPROVADO_MOTOR",
    color: "negative",
    txt: "Indicação Reprovada",
  },
  {
    status: "AGUARDANDO_ACEITE",
    color: "positive_dark",
    txt: "Aguardando Aceite",
  },
  {
    status: "PROPOSTA_RECUSADA",
    color: "negative",
    txt: "Proposta Recusada",
  },
  {
    status: "PENDENTE_DOCUMENTACAO",
    color: "warning",
    txt: "Documentação Pendente",
  },
  {
    status: "ANALISE_DOCUMENTACAO",
    color: "positive_dark",
    txt: "Documentação em Análise",
  },
  {
    status: "DOCUMENTO_REJEITADO",
    color: "warning",
    txt: "Documentação Rejeitada",
  },
  {
    status: "VENCIDO",
    color: "negative",
    txt: "Proposta Expirou",
  },
  {
    status: "ENVIO_NFE",
    color: "positive_dark",
    txt: "NFe em Envio",
  },
  {
    status: "EM_CADASTRO",
    color: "positive_dark",
    txt: "Em Cadastro",
  },
  {
    status: "AGUARDANDO_ASSINATURA",
    color: "positive_dark",
    txt: "Aguardando Assinatura",
  },
  {
    status: "AGUARDANDO_DESEMBOLSO",
    color: "positive_dark",
    txt: "Aguardando Desembolso",
  },
  {
    status: "COMPROVANTE_SERVICO",
    color: "positive_dark",
    txt: "Envio de Comprovante de Svç",
  },
  {
    status: "ANALISE_DOCUMENTACAO_CONTRATACAO",
    color: "positive_dark",
    txt: "Documentação em Análise",
  },
  {
    status: "AJUSTE_DOCUMENTACAO",
    color: "warning",
    txt: "Ajuste de Documentação",
  },
  {
    status: "CONCLUIDO",
    color: "positive_light",
    txt: "Concluído",
  },
];

export const PROPOSAL_CUSTOMIZATION_FORM_DATA = {
  validity: {
    value: "15",
    isValid: true,
  },
  validityDate: {
    value: dateFormat(15),
    isValid: true,
  },
  theme: {
    value: "",
    isValid: true,
  },
};

export const CUSTOMER_REGISTER_FORM_DATA = {
  email: {
    value: "",
    isValid: true,
  },
  name: {
    value: "",
    isValid: false,
  },
  uf: {
    value: "",
    isValid: true,
  },
  city: {
    value: "",
    isValid: true,
  },
  phone: {
    value: "",
    isValid: true,
  },
  whatsapp: {
    value: "",
    isValid: true,
  },
  address: {
    value: "",
    isValid: true,
  },
  cpfCnpjSwitch: {
    value: false,
    isValid: true,
  },
  document: {
    value: "",
    isValid: true,
  },
};

export const PROPOSAL_STEP_ONE_UNDEF_CUST_FORM_DATA = {
  email: {
    value: "",
    isValid: true,
  },
  name: {
    value: "",
    isValid: false,
  },
  uf: {
    value: "",
    isValid: false,
  },
  city: {
    value: "",
    isValid: false,
  },
  lat: {
    value: "",
    isValid: false,
  },
  lng: {
    value: "",
    isValid: false,
  },
  kwhPrice: {
    value: "",
    isValid: false,
  },
  networkClass: {
    value: "",
    isValid: false,
  },
  publicLightBill: {
    value: "",
    isValid: false,
  },
  genPercentage: {
    value: "100",
    isValid: true,
  },
  averageProposal: {
    value: false,
    isValid: true,
  },
  averageValue: {
    value: "N/A",
    isValid: true,
  },
  jan: {
    value: "",
    isValid: false,
  },
  feb: {
    value: "",
    isValid: false,
  },
  mar: {
    value: "",
    isValid: false,
  },
  apr: {
    value: "",
    isValid: false,
  },
  may: {
    value: "",
    isValid: false,
  },
  jun: {
    value: "",
    isValid: false,
  },
  jul: {
    value: "",
    isValid: false,
  },
  aug: {
    value: "",
    isValid: false,
  },
  sep: {
    value: "",
    isValid: false,
  },
  oct: {
    value: "",
    isValid: false,
  },
  nov: {
    value: "",
    isValid: false,
  },
  dec: {
    value: "",
    isValid: false,
  },
  losses: {
    value: "25",
    isValid: true,
  },
  availabilityTaxDiscount: {
    value: false,
    isValid: true,
  },
  detailedTaxation: {
    value: false,
    isValid: true,
  },
  simultaneityFactor: {
    value: "30",
    isValid: true,
  },
  tusd: {
    value: "",
    isValid: true,
  },
  icms: {
    value: "",
    isValid: true,
  },
};

export const PROPOSAL_STEP_THREE_FIN_FORM_DATA = {
  pvKitCost: {
    value: "",
    isValid: false,
  },
  installationCost: {
    value: "",
    isValid: true,
  },
  projectCost: {
    value: "",
    isValid: false,
  },
  transportFare: {
    value: "",
    isValid: false,
  },
  acCost: {
    value: "",
    isValid: false,
  },
  travelCost: {
    value: "",
    isValid: false,
  },
  transformerCost: {
    value: "",
    isValid: false,
  },
  additionalCost: {
    value: "",
    isValid: false,
  },
  nfValue: {
    value: "",
    isValid: false,
  },
  taxes: {
    value: "10",
    isValid: true,
  },
  inflation: {
    value: "10",
    isValid: true,
  },
  marginEquip: {
    value: "0",
    isValid: true,
  },
  margin: {
    value: "20",
    isValid: true,
  },
  equipamentProfit: {
    value: "",
    isValid: false,
  },
  financing: {
    value: false,
    isValid: true,
  },
  bank: {
    value: "",
    isValid: true,
  },
  entry: {
    value: "",
    isValid: true,
  },
  installment1: {
    value: "6",
    isValid: true,
  },
  value1: {
    value: "",
    isValid: true,
  },
  installment2: {
    value: "12",
    isValid: true,
  },
  value2: {
    value: "",
    isValid: true,
  },
  installment3: {
    value: "24",
    isValid: true,
  },
  value3: {
    value: "",
    isValid: true,
  },
  installment4: {
    value: "36",
    isValid: true,
  },
  value4: {
    value: "",
    isValid: true,
  },
  installment5: {
    value: "48",
    isValid: true,
  },
  value5: {
    value: "",
    isValid: true,
  },
  installment6: {
    value: "60",
    isValid: true,
  },
  value6: {
    value: "",
    isValid: true,
  },
  financing1: {
    value: false,
    isValid: true,
  },
  bank1: {
    value: "",
    isValid: true,
  },
  entry1: {
    value: "",
    isValid: true,
  },
  installment11: {
    value: "6",
    isValid: true,
  },
  value11: {
    value: "",
    isValid: true,
  },
  installment21: {
    value: "12",
    isValid: true,
  },
  value21: {
    value: "",
    isValid: true,
  },
  installment31: {
    value: "24",
    isValid: true,
  },
  value31: {
    value: "",
    isValid: true,
  },
  installment41: {
    value: "36",
    isValid: true,
  },
  value41: {
    value: "",
    isValid: true,
  },
  installment51: {
    value: "48",
    isValid: true,
  },
  value51: {
    value: "",
    isValid: true,
  },
  installment61: {
    value: "60",
    isValid: true,
  },
  value61: {
    value: "",
    isValid: true,
  },
  financing2: {
    value: false,
    isValid: true,
  },
  bank2: {
    value: "",
    isValid: true,
  },
  entry2: {
    value: "",
    isValid: true,
  },
  installment12: {
    value: "6",
    isValid: true,
  },
  value12: {
    value: "",
    isValid: true,
  },
  installment22: {
    value: "12",
    isValid: true,
  },
  value22: {
    value: "",
    isValid: true,
  },
  installment32: {
    value: "24",
    isValid: true,
  },
  value32: {
    value: "",
    isValid: true,
  },
  installment42: {
    value: "36",
    isValid: true,
  },
  value42: {
    value: "",
    isValid: true,
  },
  installment52: {
    value: "48",
    isValid: true,
  },
  value52: {
    value: "",
    isValid: true,
  },
  installment62: {
    value: "60",
    isValid: true,
  },
  value62: {
    value: "",
    isValid: true,
  },
  totalCost: {
    value: "",
    isValid: false,
  },
  liquidServices: {
    value: "",
    isValid: false,
  },
  costPerKwp: {
    value: "",
    isValid: false,
  },
  profit: {
    value: "",
    isValid: false,
  },
  profitPerKwp: {
    value: "",
    isValid: false,
  },
  finalPrice: {
    value: "",
    isValid: false,
  },
};

export const PROPOSAL_STEP_THREE_FIN_EASY_FORM_DATA = {
  pvKitCost: {
    value: "",
    isValid: false,
  },
  servicePricePerkWp: {
    value: "",
    isValid: true,
  },
  inflation: {
    value: "10",
    isValid: true,
  },
  financing: {
    value: false,
    isValid: true,
  },
  bank: {
    value: "",
    isValid: true,
  },
  entry: {
    value: "",
    isValid: true,
  },
  installment1: {
    value: "6",
    isValid: true,
  },
  value1: {
    value: "",
    isValid: true,
  },
  installment2: {
    value: "12",
    isValid: true,
  },
  value2: {
    value: "",
    isValid: true,
  },
  installment3: {
    value: "24",
    isValid: true,
  },
  value3: {
    value: "",
    isValid: true,
  },
  installment4: {
    value: "36",
    isValid: true,
  },
  value4: {
    value: "",
    isValid: true,
  },
  installment5: {
    value: "48",
    isValid: true,
  },
  value5: {
    value: "",
    isValid: true,
  },
  installment6: {
    value: "60",
    isValid: true,
  },
  value6: {
    value: "",
    isValid: true,
  },
  financing1: {
    value: false,
    isValid: true,
  },
  bank1: {
    value: "",
    isValid: true,
  },
  entry1: {
    value: "",
    isValid: true,
  },
  installment11: {
    value: "6",
    isValid: true,
  },
  value11: {
    value: "",
    isValid: true,
  },
  installment21: {
    value: "12",
    isValid: true,
  },
  value21: {
    value: "",
    isValid: true,
  },
  installment31: {
    value: "24",
    isValid: true,
  },
  value31: {
    value: "",
    isValid: true,
  },
  installment41: {
    value: "36",
    isValid: true,
  },
  value41: {
    value: "",
    isValid: true,
  },
  installment51: {
    value: "48",
    isValid: true,
  },
  value51: {
    value: "",
    isValid: true,
  },
  installment61: {
    value: "60",
    isValid: true,
  },
  value61: {
    value: "",
    isValid: true,
  },
  financing2: {
    value: false,
    isValid: true,
  },
  bank2: {
    value: "",
    isValid: true,
  },
  entry2: {
    value: "",
    isValid: true,
  },
  installment12: {
    value: "6",
    isValid: true,
  },
  value12: {
    value: "",
    isValid: true,
  },
  installment22: {
    value: "12",
    isValid: true,
  },
  value22: {
    value: "",
    isValid: true,
  },
  installment32: {
    value: "24",
    isValid: true,
  },
  value32: {
    value: "",
    isValid: true,
  },
  installment42: {
    value: "36",
    isValid: true,
  },
  value42: {
    value: "",
    isValid: true,
  },
  installment52: {
    value: "48",
    isValid: true,
  },
  value52: {
    value: "",
    isValid: true,
  },
  installment62: {
    value: "60",
    isValid: true,
  },
  value62: {
    value: "",
    isValid: true,
  },
  liquidServices: {
    value: "",
    isValid: false,
  },
  costPerKwp: {
    value: "",
    isValid: false,
  },
  finalPrice: {
    value: "",
    isValid: false,
  },
};

export const NO_KIT_LOADED_DATA = {
  id: null,
  kitData: {
    kitPrice: null,
    kitPower: null,
    kitSupplier: null,
  },
  pvModuleData: {
    manufacturer: null,
    power: null,
    warrantyDefect: null,
    warrantyEfficiency: null,
    quantity: null,
    weight: null,
    mLength: null,
    width: null,
    model1: false,
    manufacturer1: null,
    power1: null,
    warrantyDefect1: null,
    warrantyEfficiency1: null,
    quantity1: null,
    weight1: null,
    mLength1: null,
    width1: null,
    model2: false,
    manufacturer2: null,
    power2: null,
    warrantyDefect2: null,
    warrantyEfficiency2: null,
    quantity2: null,
    weight2: null,
    mLength2: null,
    width2: null,
    model3: false,
    manufacturer3: null,
    power3: null,
    warrantyDefect3: null,
    warrantyEfficiency3: null,
    quantity3: null,
    weight3: null,
    mLength3: null,
    width3: null,
    model4: false,
    manufacturer4: null,
    power4: null,
    warrantyDefect4: null,
    warrantyEfficiency4: null,
    quantity4: null,
    weight4: null,
    mLength4: null,
    width4: null,
  },
  inverterData: [
    {
      iManufacturer: null,
      iModel: null,
      iPower: null,
      iQuantity: null,
      iWarranty: null,
      iMonitoring: null,
    },
  ],
  gotInverter: false,
  registryDate: null,
  user: null,
};

export const NO_INVERTER_DATA = [
  {
    iManufacturer: null,
    iModel: null,
    iPower: null,
    iQuantity: null,
    iWarranty: null,
    iMonitoring: null,
  },
];

export const SIGNUP_PRICES_FORM_DATA = {
  simple: {
    value: "",
    isValid: false,
  },
  crm: {
    value: "",
    isValid: false,
  },
  enterprise: {
    value: "",
    isValid: false,
  },
  i02x: {
    value: "",
    isValid: false,
  },
  i03x: {
    value: "",
    isValid: false,
  },
  i04x: {
    value: "",
    isValid: false,
  },
  i05x: {
    value: "",
    isValid: false,
  },
  i06x: {
    value: "",
    isValid: false,
  },
  i07x: {
    value: "",
    isValid: false,
  },
  i08x: {
    value: "",
    isValid: false,
  },
  i09x: {
    value: "",
    isValid: false,
  },
  i10x: {
    value: "",
    isValid: false,
  },
  i11x: {
    value: "",
    isValid: false,
  },
  i12x: {
    value: "",
    isValid: false,
  },
};

export const VENDORS_AMOUNT_SLIDER_MARKS = [
  {
    value: 2,
    label: "2",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 35,
    label: "35",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 45,
    label: "45",
  },
  {
    value: 50,
    label: "50",
  },
];

export const LEAD_TEMPERATURE = [
  { value: -1, label: "" },
  { value: 0, label: "Frio" },
  { value: 1, label: "Morno" },
  { value: 2, label: "Quente" },
];

export const ORDER_MOD_IMGS: { mod: AzumeModules; img: string }[] = [
  { mod: "Proposta 2.0", img: "mod_prop.png" },
  { mod: "Proposta Personalizada", img: "mod_cust_prop.png" },
  { mod: "Assinatura Digital", img: "mod_ass.png" },
  { mod: "Gerador de Propostas de Pós-Venda", img: "mod_pos_venda.png" },
  { mod: "Implementação em Grupo Conta", img: "mod_imp_gp.png" },
  { mod: "Implementação Exclusiva Conta", img: "mod_imp.png" },
  { mod: "Implementação Website", img: "mod_web.png" },
  { mod: "Implementação Domínio", img: "mod_dom.png" },
];

export const ACTIVE_VIEW_MAP = {
  home: { view: 100, url: "/inicio" },
  starting: { view: 101, url: "/iniciando" },
  userInfoEdit: { view: 200, url: "/conta/info/:uid" },
  userPasswordoEdit: { view: 201, url: "/conta/senha/:uid" },
  customerList: { view: 300, url: "/clientes/lista/:uid" },
  customerCreate: { view: 301, url: "/cliente/cadastro" },
  customerEdit: { view: 302, url: "/cliente/editar/:cid" },
  customersUpload: { view: 303, url: "/upload-de-clientes" },
  customerFiles: {
    view: 304,
    url: "/arquivos/:cid",
  },
  customerExport: { view: 305, url: "/cliente/exportacao" },
  kitsList: { view: 400, url: "/kits/lista/:uid" },
  kitsChoice: {
    view: 401,
    url: "/kits/escolha/:uid/:pid/:power/:powerGenUc/:powerNonPeak/:powerPeak/:demand/:targetPower/:ownerId/:distance/:location",
  },
  kitsUpload: { view: 402, url: "/upload-de-kits" },
  kitsSuppliersGeneratorsList: {
    view: 402,
    url: "/kits/fornecedores/:uid/:pid/:power/:powerGenUc/:powerNonPeak/:powerPeak/:demand/:targetPower/:ownerId/:distance/:location",
  },
  kitsCreate: { view: 403, url: "/kits/cadastro/:kitId" },
  kitsEdit: { view: 404, url: "/kits/editar/:kitId" },
  proposalsAllList: { view: 500, url: "/propostas/lista/:uid" },
  proposalsCustomerList: { view: 501, url: "/propostas/cliente/:cid" },
  proposalsCreateStep1New: {
    view: 502,
    url: "/proposta/gerar-proposta/dados-consumo/nova",
  },
  // proposalsCreateStep1DefCustomer: {
  //   view: 503,
  //   url: "/proposta/gerar-proposta/dados-consumo/nova/:cid/:euid",
  // },
  // proposalsCreateStep1AddUc: {
  //   view: 504,
  //   url: "/proposta/gerar-proposta/dados-consumo/uc/nova/:pid/:ucid",
  // },
  proposalsCreateStep1Edit: {
    view: 505,
    url: "/proposta/gerar-proposta/dados-consumo/editar",
  },
  proposalsCreateStep2KitChoice: {
    view: 506,
    url: "/proposta/gerar-proposta/escolha-kit/:pid",
  },
  proposalsCreateStep2KitData: {
    view: 507,
    url: "/proposta/gerar-proposta/dados-geracao/nova/:pid",
  },
  proposalsCreateStep3PricingChoice: {
    view: 508,
    url: "/proposta/gerar-proposta/escolha/precificacao/:pid",
  },
  proposalsCreateStep3PricingManual: {
    view: 509,
    url: "/proposta/gerar-proposta/preco-manual/:pid",
  },
  proposalsCreateStep3PricingAuto: {
    view: 510,
    url: "/proposta/gerar-proposta/preco-auto/:pid",
  },
  proposalsCreateStep4Theme: {
    view: 511,
    url: "/proposta/gerar-proposta/personalizacao/:pid",
  },
  proposalsFinalDigital: {
    view: 512,
    url: "/proposta/final/:pid",
  },
  proposalsFinalDigitalMobile: {
    view: 513,
    url: "/proposta/final/mobile/:pid",
  },
  proposalsFinalPDFPrint: {
    view: 515,
    url: "/proposta/final/impressao/:pid",
  },
  proposalsFinalPDFFinancing: {
    view: 516,
    url: "/proposta/final/financiamento/:pid",
  },
  customizationMenu: {
    view: 517,
    url: "/personalizar-proposta/:uid",
  },
  proposalsCreateStep3PricingEdit: {
    view: 518,
    url: "/proposta/gerar-proposta/preco-editar/:pid",
  },
  proposalsCreateStep3Validation: {
    view: 519,
    url: "/proposta/gerar-proposta/validacao/:pid",
  },
  proposalsCreateStep3Financing: {
    view: 519,
    url: "/proposta/gerar-proposta/financiamento/:pid",
  },
  salesFunnel: {
    view: 600,
    url: "/vendas/funil/:uid",
  },
  salesCreate: {
    view: 601,
    url: "/vendas/registro",
  },
  salesEdit: {
    view: 602,
    url: "/vendas/editar/:sid",
  },
  salesData: {
    view: 603,
    url: "/vendas/dados/:uid",
  },
  salesSchedulesList: {
    view: 604,
    url: "/vendas/agenda/:uid",
  },
  salesArchivedList: {
    view: 605,
    url: "/vendas/finalizadas",
  },
  salesUpload: {
    view: 606,
    url: "/upload-de-vendas",
  },
  salesExport: {
    view: 607,
    url: "/vendas/exportacao",
  },
  updatessList: {
    view: 700,
    url: "/atualizacoes",
  },
  managerVendors: {
    view: 800,
    url: "/colaboradores/:managerId",
  },
  tutorialsCourse: {
    view: 900,
    url: "/tutoriais",
  },
  ordersMenu: {
    view: 1000,
    url: "/financeiro",
  },
  ordersCancel: {
    view: 1001,
    url: "/cancelamento",
  },
  settings: {
    view: 1002,
    url: "/configuracoes",
  },
  contactForm: {
    view: 1100,
    url: "/contato",
  },
  loginUser: {
    view: 1200,
    url: "/login",
  },
  loginAdmin1: {
    view: 1201,
    url: "/admin1",
  },
  loginAdmin2: {
    view: 1202,
    url: "/admin2",
  },
  loginPasswordRecovery: {
    view: 1203,
    url: "/recuperacao-de-acesso",
  },
  signupAbout: {
    view: 1300,
    url: "/sobre",
  },
  signupAboutMobile: {
    view: 1301,
    url: "/sobre/mobile",
  },
  signupStep1: {
    view: 1302,
    url: "/assinatura/passo-1/:planId",
  },
  signupStep3Choice: {
    view: 1303,
    url: "/assinatura/escolha-pagamento/:planId/:suId",
  },
  signupStep3Card: {
    view: 1304,
    url: "/assinatura/passo-3/:planId/:suId",
  },
  signupTYPCard: {
    view: 1304,
    url: "/assinatura/parabens/:planId/:suId",
  },
  signupTYPBill: {
    view: 1305,
    url: "/assinatura/boleto/parabens/:suId",
  },
  signupTYPPix: {
    view: 1306,
    url: "/assinatura/pix/parabens/:suId",
  },
  websiteView: {
    view: 1400,
    url: "/institucional/:id",
  },
  websiteEdit: {
    view: 1401,
    url: "/editar-website/:uid",
  },
  contracts: {
    view: 1500,
    url: "/contratos",
  },
  contractEdit: {
    view: 1501,
    url: "/editar-contrato/:contractId",
  },
  contractChoice: {
    view: 1502,
    url: "/escolher-contrato/:saleId",
  },
  generateContract: {
    view: 1503,
    url: "/gerar-contrato/:contractId/:saleId",
  },
  saleContracts: {
    view: 1504,
    url: "/visualizar-contratos-da-venda/:saleId",
  },
  viewSaleContract: {
    view: 1505,
    url: "/visualizar-contrato/:contractId/:saleId",
  },
  projectsKanban: {
    view: 1600,
    url: "/projetos/quadro/:uid",
  },
  projectsCreate: {
    view: 1601,
    url: "/projetos/registro",
  },
  projectsEdit: {
    view: 1602,
    url: "/projetos/editar/:sid",
  },
  projectsSchedulesList: {
    view: 1603,
    url: "/projetos/agenda/:uid",
  },
  projectsFinishedList: {
    view: 1604,
    url: "/projetos/finalizadas",
  },
  projectsData: {
    view: 1605,
    url: "/projetos/dados/:uid",
  },
  installationsKanban: {
    view: 1700,
    url: "/instalacoes/quadro/:uid",
  },
  installationsCreate: {
    view: 1701,
    url: "/instalacoes/registro",
  },
  installationsEdit: {
    view: 1702,
    url: "/instalacoes/editar/:sid",
  },
  installationsSchedulesList: {
    view: 1703,
    url: "/instalacoes/agenda/:uid",
  },
  installationsFinishedList: {
    view: 1704,
    url: "/instalacoes/finalizadas",
  },
  installationsData: {
    view: 1705,
    url: "/instalacoes/dados/:uid",
  },
  afterSalesKanban: {
    view: 1800,
    url: "/pos-venda/quadro/:uid",
  },
  afterSalesCreate: {
    view: 1801,
    url: "/pos-venda/registro",
  },
  afterSalesEdit: {
    view: 1802,
    url: "/pos-venda/editar/:sid",
  },
  afterSalesSchedulesList: {
    view: 1803,
    url: "/pos-venda/agenda/:uid",
  },
  afterSalesFinishedList: {
    view: 1804,
    url: "/pos-venda/finalizadas",
  },
  afterSalesTemplates: {
    view: 1805,
    url: "/pos-venda/templates",
  },
  modulesList: {
    view: 1900,
    url: "/modulos/lista",
  },
  moduleRegister: {
    view: 1901,
    url: "/modulos/registro",
  },
  moduleEdit: {
    view: 1902,
    url: "/modulos/editar/:mid",
  },
  modulesUpload: {
    view: 1903,
    url: "/modulos/upload",
  },
  invertersList: {
    view: 2000,
    url: "/inversores/lista",
  },
  inverterRegister: {
    view: 2001,
    url: "/inversores/registro",
  },
  inverterEdit: {
    view: 2002,
    url: "/inversores/editar/:iid",
  },
  invertersUpload: {
    view: 2003,
    url: "/inversores/upload",
  },
  equipamentsList: {
    view: 2100,
    url: "/equipamentos/lista",
  },
  equipamentRegister: {
    view: 2101,
    url: "/equipamentos/registro",
  },
  equipamentEdit: {
    view: 2102,
    url: "/equipamentos/editar/:eid",
  },
  equipamentsUpload: {
    view: 2103,
    url: "/equipamentos/upload",
  },
  importsList: {
    view: 2200,
    url: "/importacoes/lista",
  },
  preSalesKanban: {
    view: 2300,
    url: "/pre-venda/funil/:uid",
  },
  preSalesCreate: {
    view: 2301,
    url: "/pre-venda/registro",
  },
  preSalesEdit: {
    view: 2302,
    url: "/pre-venda/editar/:sid",
  },
  preSalesSchedulesList: {
    view: 2303,
    url: "/pre-venda/agenda/:uid",
  },
  preSalesFinishedList: {
    view: 2304,
    url: "/pre-venda/finalizadas",
  },
  preSalesData: {
    view: 2305,
    url: "/pre-venda/dados/:uid",
  },
  preSalesUpload: {
    view: 2306,
    url: "/upload-de-pre-vendas",
  },
  preSalesExport: {
    view: 2307,
    url: "/pre-venda/exportacao",
  },
  administrativeKanban: {
    view: 2400,
    url: "/administrativo/quadro/:uid",
  },
  administrativeCreate: {
    view: 2401,
    url: "/administrativo/registro",
  },
  administrativeEdit: {
    view: 2402,
    url: "/administrativo/editar/:sid",
  },
  administrativeSchedulesList: {
    view: 2403,
    url: "/administrativo/agenda/:uid",
  },
  administrativeFinishedList: {
    view: 2404,
    url: "/administrativo/finalizadas",
  },
  financings: { view: 2500, url: "/financiamentos" },
  chats: { view: 2600, url: "/chats" },
  orders: { view: 2700, url: "/pedidos" },
};

export const PROPOSAL_USER_DATA_TAGS = [
  { tag: "{nome_da_empresa}", description: "Nome da empresa" },
  { tag: "{telefone_da_empresa}", description: "Telefone da empresa" },
  { tag: "{whatsapp_da_empresa}", description: "WhatsApp da empresa" },
  { tag: "{endereco_da_empresa}", description: "Endereço da empresa" },
  { tag: "{cnpj_da_empresa}", description: "CNPJ da empresa" },
  { tag: "{website_da_empresa}", description: "Website da empresa" },
  { tag: "{facebook_da_empresa}", description: "Facebook da empresa" },
  { tag: "{instagram_da_empresa}", description: "Instagram da empresa" },
  {
    tag: "{nome_responsavel}",
    description: "Nome do responsável pela proposta",
  },
  {
    tag: "{telefone_responsavel}",
    description: "Telefone do responsável pela proposta",
  },
  {
    tag: "{email_responsavel}",
    description: "E-mail do responsável pela proposta",
  },
];

export const PROPOSAL_CUSTOMER_DATA_TAGS = [
  { tag: "{nome_do_cliente}", description: "Nome do cliente" },
  { tag: "{cidade_do_cliente}", description: "Cidade do cliente" },
  { tag: "{endereco_do_cliente}", description: "Endereço do cliente" },
  { tag: "{whatsapp_do_cliente}", description: "WhatsApp do cliente" },
  { tag: "{email_do_cliente}", description: "E-mail do cliente" },
  { tag: "{documento_do_cliente}", description: "CPF/CNPJ do cliente" },
];

export const PROPOSAL_GENERAL_TAGS = [
  { tag: "{numero_da_proposta}", description: "Número da proposta" },
  { tag: "{data_validade}", description: "Data de validade da proposta" },
  {
    tag: "{dias_validade}",
    description: "Número de dias de validade da proposta",
  },
  {
    tag: "{data_registro}",
    description: "Data de registro da proposta",
  },
  {
    tag: "{cond_pag}",
    description: "Condições de pagamento do sistema proposto",
  },
  {
    tag: "{fornecedor}",
    description: "Fornecedor do Kit FV",
  },
  {
    tag: "{est_fix}",
    description: "Estrutura de fixação do Kit FV",
  },
  {
    tag: "{cons_finais_t}",
    description: "Título das considerações finais",
  },
  {
    tag: "{cons_finais_p1}",
    description: "Parágrafo 1 das considerações finais",
  },
  {
    tag: "{cons_finais_p2}",
    description: "Parágrafo 2 das considerações finais",
  },
  {
    tag: "{cons_finais_p3}",
    description: "Parágrafo 3 das considerações finais",
  },
  {
    tag: "{cons_finais_p4}",
    description: "Parágrafo 4 das considerações finais",
  },
  {
    tag: "{cons_finais_p5}",
    description: "Parágrafo 5 das considerações finais",
  },
  {
    tag: "{cons_finais_p6}",
    description: "Parágrafo 6 das considerações finais",
  },
];

export const PROPOSAL_PROJECT_TAGS = [
  { tag: "{cs_m}", description: "Consumo médio mensal em kWh/mês" },
  {
    tag: "{cs_m_fp}",
    description: "Consumo médio mensal fora ponta em kWh/mês",
  },
  { tag: "{cs_m_p}", description: "Consumo médio mensal ponta em kWh/mês" },
  { tag: "{cs_a}", description: "Consumo médio anual em kWh/ano" },
  {
    tag: "{cs_a_fp}",
    description: "Consumo médio anual fora ponta em kWh/ano",
  },
  { tag: "{cs_a_p}", description: "Consumo médio anual ponta em kWh/ano" },
  { tag: "{ger_m}", description: "Geração média mensal estimada em kWh/mês" },
  { tag: "{ger_a}", description: "Geração média anual estimada em kWh/ano" },
  { tag: "{co2}", description: "Redução de CO² em kg/ano" },
  { tag: "{arvores}", description: "Árvores salvas" },
  { tag: "{carros}", description: "Anos de um carro fora de circulação" },
];

export const PROPOSAL_UCS_LOOP_TAGS = [
  {
    tag: "{#ucs_dist}",
    description: "Início do loop de distrinuição de energia entre UCs",
  },
  {
    tag: "{/ucs_dist}",
    description: "Final do loop de distrinuição de energia entre UCs",
  },
  {
    tag: "{nome_uc}",
    description: "Nome da UC",
  },
  {
    tag: "{ger_uc}",
    description: "Quantidade em kWh consumida da geração pela UC",
  },
  {
    tag: "{ger_perc_uc}",
    description: "Quantidade em % consumida da geração pela UC",
  },
  {
    tag: "{c_ss_uc}",
    description: "Valor mensal da conta de energia SEM sistema da UC",
  },
  {
    tag: "{c_cs_uc}",
    description: "Valor mensal da conta de energia COM sistema da UC",
  },
  {
    tag: "{ec_uc}",
    description: "Economia mensal da UC",
  },
];

export const PROPOSAL_SYSTEM_INFO_TAGS = [
  { tag: "{potencia}", description: "Potência real do sistema em kWp" },
  {
    tag: "{potencia_calc}",
    description: "Potência ideal calculada do sistema em kWp",
  },
  { tag: "{area}", description: "Área mínima requerida pelo sistema em m²" },
  { tag: "{peso_dist}", description: "Peso distribuído do sistema em kg/m²" },
  {
    tag: "{irr}",
    description:
      "Irradiação solar média anual em kWh/m².dia (plano horizontal 0°)",
  },
];

export const PROPOSAL_GRAPHS_TAGS = [
  { tag: "{$grafico_ger}", description: "Gráfico de consumo X geração" },
  {
    tag: "{$grafico_fat_a1}",
    description:
      "Gráfico da fatura de energia COM e SEM sistema no primeiro ano",
  },
  {
    tag: "{$grafico_fluxo_caixa}",
    description: "Gráfico do fluxo de caixa",
  },
  {
    tag: "{$grafico_comp_rend}",
    description: "Gráfico de comparação de rendimentos",
  },
];

export const PROPOSAL_EQP_GEN_TAGS = [
  {
    tag: "{sobre_eqps}",
    description: "Sobre os equipamentos",
  },
];

export const PROPOSAL_LOOP_PV_MODS_TAGS = [
  {
    tag: "{#modulos}",
    description: "Início do loop de módulos fotovoltaicos",
  },
  {
    tag: "{/modulos}",
    description: "Final do loop de módulos fotovoltaicos",
  },
  {
    tag: "{mod_fab}",
    description: "Fabricante do módulo",
  },
  {
    tag: "{mod_pot}",
    description: "Potência do módulo em Wp",
  },
  {
    tag: "{mod_gar_def}",
    description: "Garantia do módulo contra defeitos em anos",
  },
  {
    tag: "{mod_gar_ef}",
    description: "Garantia de eficiência de 80% do módulo em anos",
  },
  {
    tag: "{mod_qtd}",
    description: "Quantidade de módulos",
  },
];

export const PROPOSAL_LOOP_INV_TAGS = [
  {
    tag: "{#inversores}",
    description: "Início do loop de inversores",
  },
  {
    tag: "{/inversores}",
    description: "Final do loop de inversores",
  },
  {
    tag: "{inv_mod}",
    description: "Modelo do inversor",
  },
  {
    tag: "{inv_fab}",
    description: "Fabricante do inversor",
  },
  {
    tag: "{inv_pot}",
    description: "Potência do inveror em W",
  },
  {
    tag: "{inv_gar}",
    description: "Garantia do inversor em anos",
  },
  {
    tag: "{inv_monit}",
    description: "Tipo de monitoramento do inversor",
  },
  {
    tag: "{inv_qtd}",
    description: "Quantidade de inversores",
  },
];

export const PROPOSAL_LOOP_ADD_ITEMS_TAGS = [
  {
    tag: "{#adicionais}",
    description: "Início do loop de itens complementares",
  },
  {
    tag: "{/adicionais}",
    description: "Final do loop de itens complementares",
  },
  {
    tag: "{item_adc_nome}",
    description: "Nome/descrição do item complementar",
  },
  {
    tag: "{item_adc_fab}",
    description: "Fabricante do item complementar",
  },
  {
    tag: "{item_adc_qtd}",
    description: "Quantidade do item complementar",
  },
  {
    tag: "{item_adc_det}",
    description: "Detalhe do item complementar",
  },
];

export const PROPOSAL_FIN_VIAB_YEAR_ONE = [
  {
    tag: "{cust_tot_ss}",
    description: "Custo da conta de energia SEM sistema",
  },
  {
    tag: "{cust_c_fp_ss}",
    description: "Custo do CONSUMO FORA PONTA da conta de energia SEM sistema",
  },
  {
    tag: "{cust_d_fp_ss}",
    description: "Custo da DEMANDA FORA PONTA",
  },
  {
    tag: "{cust_c_p_ss}",
    description: "Custo do CONSUMO PONTA da conta de energia SEM sistema",
  },
  {
    tag: "{cust_d_p_ss}",
    description: "Custo da DEMANDA PONTA",
  },
  {
    tag: "{cust_d_ger_ss}",
    description: "Custo da DEMANDA GERAÇÃO",
  },
  {
    tag: "{cust_tot_cs}",
    description: "Custo da conta de energia COM sistema",
  },
  {
    tag: "{cust_c_fp_abt}",
    description: "Custo ABATIDO do CONSUMO FORA PONTA na conta de energia",
  },
  {
    tag: "{cust_c_p_abt}",
    description: "Custo ABATIDO do CONSUMO PONTA na conta de energia",
  },
  {
    tag: "{cust_a1_ss}",
    description: "Custo estimado do primeiro ano SEM sistema",
  },
  {
    tag: "{cust_a1_cs}",
    description: "Custo estimado do primeiro ano COM sistema",
  },
  {
    tag: "{ecn_a1_am}",
    description: "Economia média mensal estimada no primeiro ano",
  },
  {
    tag: "{ecn_a1_aa}",
    description: "Economia total estimada no primeiro ano",
  },
];

export const PROPOSAL_FIN_VIAB_INDICATORS = [
  {
    tag: "{preco_sist}",
    description: "Preço do sistema em R$",
  },
  {
    tag: "{preco_servico}",
    description: "Preço do serviço em R$",
  },
  {
    tag: "{preco_equipamentos}",
    description: "Preço dos equipamento em R$",
  },
  {
    tag: "{infl_en}",
    description: "Reajuste anual de energia em %",
  },
  {
    tag: "{payback_aa}",
    description: "Anos do payback",
  },
  {
    tag: "{payback_mm}",
    description: "Meses do payback",
  },
  {
    tag: "{roi}",
    description: "Retorno sobre o investimento (ROI)",
  },
  {
    tag: "{tir}",
    description: "Taxa interna de retorno (TIR)",
  },
  {
    tag: "{vl_sist_fv}",
    description: "Valor do kWh com o sistema FV",
  },
  {
    tag: "{vl_ecn_sist_fv}",
    description: "Valor economizado por kWh com o sistema FV",
  },
  {
    tag: "{ecn_tot}",
    description: "Economia total nos 25 anos da análise financeira",
  },
];

export const PROPOSAL_LOOP_FIN_OPTS = [
  {
    tag: "{#fin}",
    description: "Início do loop de opções de financiamento",
  },
  {
    tag: "{/fin}",
    description: "Final do loop de opções de financiamento",
  },
  {
    tag: "{fin_banco}",
    description: "Banco do financiamento",
  },
  {
    tag: "{fin_cr}",
    description: "Carência do financiamento em dias",
  },
  {
    tag: "{fin_ent_parc}",
    description: "Número de parcelas da entrada do financimento",
  },
  {
    tag: "{fin_ent_val}",
    description: "Valor da entrada do financiamento em R$",
  },
  {
    tag: "{fin_seg}",
    description:
      "Dado que informa se o seguro está ou não incluso no financiamento",
  },
  {
    tag: "{fin_opc_1_parc}",
    description: "Número de parcelas da opção 1 do financiamento",
  },
  {
    tag: "{fin_opc_1_val}",
    description: "Valor da parcela da opção 1 do financiamento em R$",
  },
  {
    tag: "{fin_opc_2_parc}",
    description: "Número de parcelas da opção 2 do financiamento",
  },
  {
    tag: "{fin_opc_2_val}",
    description: "Valor da parcela da opção 2 do financiamento em R$",
  },
  {
    tag: "{fin_opc_3_parc}",
    description: "Número de parcelas da opção 3 do financiamento",
  },
  {
    tag: "{fin_opc_3_val}",
    description: "Valor da parcela da opção 3 do financiamento em R$",
  },
  {
    tag: "{fin_opc_4_parc}",
    description: "Número de parcelas da opção 4 do financiamento",
  },
  {
    tag: "{fin_opc_4_val}",
    description: "Valor da parcela da opção 4 do financiamento em R$",
  },
];

export const PROPOSAL_LOOP_FIN_OPTS_VIAB = [
  {
    tag: "{#fin_viab}",
    description:
      "Início do loop de análise de viabilidade para opções de financiamento",
  },
  {
    tag: "{/fin_viab}",
    description:
      "Final do loop de análise de viabilidade para opções de financiamento",
  },
  {
    tag: "{fin_viab_val_tot}",
    description: "Valor total do financiamento em R$",
  },
  {
    tag: "{fin_viab_breakeven_aa}",
    description: "Anos do breakeven do financiamento",
  },
  {
    tag: "{fin_viab_breakeven_mm}",
    description: "Meses do breakeven do financiamento",
  },
  {
    tag: "{fin_viab_payback_aa}",
    description: "Anos do payback do financiamento",
  },
  {
    tag: "{fin_viab_payback_mm}",
    description: "Meses do payback do financiamento",
  },
  {
    tag: "{fin_viab_roi}",
    description: "Retorno sobre o investimento (ROI) do financimento",
  },
  {
    tag: "{fin_viab_ecn_tot}",
    description: "Economia total nos 25 anos de análise do financiamento",
  },
];

export const PROPOSAL_LOOP_DET_VIAB = [
  {
    tag: "{#viab_d}",
    description: "Início do loop de análise de viabilidade detalhada",
  },
  {
    tag: "{/viab_d}",
    description: "Final do loop de análise de viabilidade detalhada",
  },
  {
    tag: "{viab_d_ano}",
    description: "Ano da análise de viabilidade detalhada",
  },
  {
    tag: "{viab_d_ss}",
    description: "Valor da fatura anual de energia sem sistema em R$",
  },
  {
    tag: "{viab_d_cs}",
    description: "Valor da fatura anual de energia com sistema em R$",
  },
  {
    tag: "{viab_d_ec}",
    description: "Valor da economia anual em R$",
  },
  {
    tag: "{viab_d_fc}",
    description: "Valor do fluxo de caixa em R$",
  },
];

export const AFTER_SALES_GEN_TAGS = [
  {
    tag: "{os}",
    description: "Número da ordem de serviço",
  },
  {
    tag: "{potencia}",
    description: "Potência do sistema do cliente",
  },
  {
    tag: "{valor_total}",
    description: "Valor do total (soma de todos os serviços)",
  },
  {
    tag: "{qtd_mods}",
    description: "Quantidade de modulos do sistema do cliente",
  },
  {
    tag: "{qtd_invs}",
    description: "Quantidade de inversores do sistema do cliente",
  },
  {
    tag: "{alt_telhado}",
    description: "Altura do telhado do cliente",
  },
  {
    tag: "{distancia}",
    description: "Distância em km",
  },
  {
    tag: "{tipo_de_inversor}",
    description: "Tipo de inversor do sistema do cliente (String ou Micro)",
  },
  {
    tag: "{validade}",
    description: "Validade da proposta",
  },
];

export const AFTER_SALES_LOOP_SERVICES_TAGS = [
  {
    tag: "{#servicos}",
    description: "Início do loop de serviços",
  },
  {
    tag: "{/servicos}",
    description: "Final do loop de serviços",
  },
  {
    tag: "{servico_nome}",
    description: "Nome do serviço",
  },
  {
    tag: "{servico_valor}",
    description: "Valor do serviço",
  },
];

export const AFTER_SALES_LOOP_SUPPLIES_TAGS = [
  {
    tag: "{#produtos}",
    description: "Início do loop de produtos",
  },
  {
    tag: "{/produtos}",
    description: "Final do loop de produtos",
  },
  {
    tag: "{produto_nome}",
    description: "Nome do produto",
  },
  {
    tag: "{produto_valor}",
    description: "Valor unitário do produto",
  },
  {
    tag: "{produto_qtd}",
    description: "Quantidade de unidades do produto",
  },
];

export const ACCOUNTS_AMOUNT_SLIDER_MARKS = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 35,
    label: "35",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 45,
    label: "45",
  },
  {
    value: 50,
    label: "50",
  },
];
